/* eslint-disable jsx-a11y/anchor-is-valid */

import React, { useState } from "react";
import "./navbar.css";

const Navbar = () => {
  const [active, setActive] = useState("nav-menu");
  const [toggleIcon, setToggleIcon] = useState("nav-toggler");

  const navToggle = () => {
    active === "nav-menu"
      ? setActive("nav-menu nav-active")
      : setActive("nav-menu");

      toggleIcon === 'nav-toggler' ? setToggleIcon('nav-toggler toggle') : setToggleIcon('nav-toggler')
  };
  return (
    <div>
      <nav className="nav">
        <a href="#" className="nav-brand">
          FIZJOTERAPIA DZIECIĘCA - AMELIA NITKA
        </a>
        <ul className={active}>
          <li className="nav-item">
            <a href="#" className="nav-link">
              STRONA GŁÓWNA
            </a>
          </li>
          <li className="nav-item">
            <a href="#about" className="nav-link">
              O MNIE
            </a>
          </li>
          <li className="nav-item">
            <a href="#courses" className="nav-link">
              KURSY I SZKOLENIA
            </a>
          </li>
          <li className="nav-item">
            <a href="#offer" className="nav-link">
              OFERTA
            </a>
          </li>
          <li className="nav-item">
            <a href="#contact" className="nav-link">
              KONTAKT
            </a>
          </li>
        </ul>
        <div onClick={navToggle} className={toggleIcon}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
