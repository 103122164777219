import React from "react";
import "./about.css";
import FirstPhoto from "../../img/first.jpg";
import Logo from "../../img/logo.png";

const About = () => {
  return (
    <div className="a" id="about">
      <div className="a-left">
        <img
          src={FirstPhoto}
          className="a-img"
          alt="obraz"
        />
      </div>
      <div className="a-right">
        <div className="a-right-wrapper">
          <h1 className="a-title">O mnie</h1>
          <div className="a-mobile-logo">
            <h1 className="a-title-mobile">O mnie</h1>
            <img src={Logo} alt="" className="a-logo"></img>
          </div>
          <p className="a-sub">
            Jestem certyfikowaną terapeutką koncepcji NDT&#8209;Bobath Baby.
          </p>
          <p className="a-sub">
            Ukończyłam studia licencjackie i&nbsp;magisterskie na kierunku "Fizjoterapia" w&nbsp;uczelni <i>Collegium Medicum</i> w&nbsp;Bydgoszczy oraz studia podyplomowe "Fizjoterapia w&nbsp;pediatrii" w&nbsp;<i>Wyższej Szkole Edukacji i&nbsp;Terapii</i> w&nbsp;Poznaniu.
          </p>
          <p className="a-sub">
            Na co dzień zajmuję się niemowlętami i&nbsp;dziećmi z&nbsp;zaburzeniami wieku rozwojowego (z&nbsp;uwzględnieniem zaburzeń neurologicznych oraz genetycznych). Pracuję również z&nbsp;pacjentami borykającymi się z&nbsp;wadami postawy. Staram się tworzyć pozytywną atmosferę, tak, aby terapia przebiegała w&nbsp;komforcie dziecka i&nbsp;rodzica.
          </p>
          <p className="a-sub">
            Świadczę wizyty domowe na&nbsp;terenie Gminy Kosakowo, Gdyni (północne dzielnice), Rumi, Redy i&nbsp;innych pobliskich miejscowości.
          </p>
        </div>
        <div>
        <img
          src={FirstPhoto}
          alt="obraz"
          className="a-img-device"
        />
        </div>
      </div>
    </div>
  );
};

export default About;
