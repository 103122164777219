import React from "react";
import SecondPhoto from "../../img/second.jpg";
import "./courses.css";


const Courses = () => {
  return (
    <div className="cr" id="courses">
      <div className="cr-left">
        <div className="cr-left-wrapper">
          <h1 className="cr-title">Kursy i szkolenia</h1>
          <div className="cr-award">
            <div className="cr-award-texts">
              <h4 className="cr-award-title">
                Oprócz studiów ukończyłam też wiele specjalistycznych kursów:
              </h4>
              <div className="cr-award-desc">
                <ul>
                  <li><b>NDT-Bobath Baby</b>,</li>
                  <li><b>NDT-Bobath Basic</b>,</li>
                  <li><b>Trójplaszczyznowa Terapia Wad Stóp u&nbsp;Dzieci</b> na Podstawach Neurofizjologicznych wg Zukunft-Huber,</li>
                  <li><b>Osteopatyczne Zabiegi Lecznicze w&nbsp;Pediatrii</b>,</li>
                  <li><b>Tkanki miękkie w&nbsp;pediatrii i&nbsp;dynamiczne plastrowanie</b>,</li>
                  <li><b>Anatomy Trains in Structure & Function</b>,</li>
                  <li><b>Proprioceptive Neuromuscular Facilitation</b> (PNF 1 & PNF 2 – M. Knott Concept),</li>
                  <li><b>Masaż Shantala Special Care</b>,</li>
                  <li><b>Sensory Babies – Master Class Course</b> (kurs z&nbsp;zakresu integracji sensorycznej niemowląt),</li>
                  <li><b>Wspomaganie rozwoju dzieci ze specyficznymi problemami w nauce</b> (Program Interwencyjny Sally Goddard Blythe oparty o&nbsp;integrację odruchów oraz ocenę neuromotorycznej gotowości do nauki <b>INPP</b>),</li>
                  <li><b>Ruch Rozwijający wg Weroniki Sherborne</b>,</li>
                  <li><b>Ocena Neurorozwojowa Dziecka i&nbsp;Przykłady Postępowania Fizjoterapeutycznego</b>.</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="cr-right">
        <img
          src={SecondPhoto}
          className="cr-img"
          alt="obraz"
        />
      </div>
    </div>
  );
};

export default Courses;
