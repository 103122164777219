import React from "react";
import "./intro.css";
import Me from "../../img/me.png";
import Logo from "../../img/logo.png";

const Intro = () => {
  return (
    <div>
      <div className="i">
        <div className="i-left">
          <div className="i-left-wrapper">
            <h1 className="i-name">Fizjoterapia dziecięca</h1>
            <h1 className="i-name">Amelia Nitka</h1>
          </div>
          <img src={Logo} alt="" className="i-logo"></img>
        </div>
        <div className="i-right">
          <div className="i-bg"></div>
          <img src={Me} alt="" className="i-img" />
        </div>
      </div>
    </div>
  );
};

export default Intro;
