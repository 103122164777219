import React from "react";
import "./contact.css";
import Phone from "../../img/phone.png";
import Email from "../../img/email.png";
import Address from "../../img/address.png";
import { useRef, useState, useEffect } from "react";
import emailjs from "@emailjs/browser";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import swal from "sweetalert";

const Contact = () => {
  const [done, setDone] = useState(false);
  const [agree, setAgree] = useState(false);
  const formRef = useRef();
  const [sendButtonClicked, setSendButtonClicked] = useState(false);
  const initialValues = {
    user_name: "",
    user_email: "",
    subject: "",
    message: "",
  };

  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    setFormErrors(validate(formValues));
    setIsSubmit(true);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormValues({ ...formValues, [name]: value });
  };

  const checkboxClicked = (e) => {
    setAgree(!agree);
  };

  useEffect(() => {
    if (Object.keys(formErrors).length === 0 && isSubmit) {
      setSendButtonClicked(true);
      emailjs
        .sendForm(
          "service_rwltej7",
          "template_3b3gm6e",
          formRef.current,
          "_rFim75AUZL2wbLW0"
        )
        .then(
          (result) => {
            setDone(true);
            swal("Super!", "Twoja wiadomość została wysłana!", "success");
            setFormValues(initialValues);
            setAgree(false);
          },
          (error) => {
            console.log(error.text);
            swal(
              "UPS!",
              "Wystąpił problem z formularzem, proszę o kontakt na dane zamieszczone po lewej stronie",
              "warning"
            );
          }
        );
    }
  }, [formErrors]);

  const validate = (values) => {
    const errors = {};
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    if (!values.user_name) {
      errors.user_name = "Imię jest wymagane!";
    } else if (values.user_name < 2) {
      errors.user_name = "Imię jest za krótkie!";
    } else if (values.user_name > 255) {
      errors.user_name = "Imię jest za długie!";
    }

    if (!values.user_email) {
      errors.user_email = "E-mail jest wymagany!";
    } else if (!regex.test(values.user_email)) {
      errors.user_email = "Adres e-mail nie jest prawidłowy!";
    } else if (values.user_email > 255) {
      errors.user_email = "Adres e-mail jest za długi!";
    }

    if (!values.subject) {
      errors.subject = "Temat wiadomości jest wymagany!";
    } else if (values.subject < 3) {
      errors.subject = "Temat wiadomości jest za krótki!";
    } else if (values.subject > 255) {
      errors.subject = "Temat wiadomości jest za długi!";
    }

    if (!values.message) {
      errors.message = "Treść wiadomości jest wymagana!";
    } else if (values.message < 3) {
      errors.message = "Treść wiadomości jest za krótka!";
    } else if (values.message > 4000) {
      errors.message = "Treść wiadomości jest za długa!";
    }

    if (!agree) {
      errors.agree = "Potwierdzenie jest wymagane!";
    }

    return errors;
  };

  return (
    <div className="c" id="contact">
      <div className="c-wrapper">
        <div className="c-left">
          <h1 className="c-title">Kontakt</h1>
          <div className="c-info">
            <div className="c-info-item">
              <img src={Phone} alt="" className="c-icon" />
              605 645 067
            </div>
            <div className="c-info-item">
              <img src={Email} alt="" className="c-icon" />
              kontakt@fizjoterapia-dziecieca.pl
            </div>
            <div className="c-info-item">
              <img src={Address} alt="" className="c-icon" />
              ul. Sezamkowa 34/2, Dębogórze
            </div>
          </div>
        </div>
        <div className="c-right">
          <div className="i-title">
            <div className="i-title-wrapper">
              <div className="i-title-item">Masz pytania?</div>
              <div className="i-title-item">
                Możesz skorzystać z poniższego formularza
              </div>
            </div>
          </div>
          <form ref={formRef} onSubmit={handleSubmit}>
            <input
              className="c-text-input"
              type="text"
              placeholder="Imię"
              name="user_name"
              value={formValues.user_name}
              onChange={handleChange}
            />
            <p className="c-errorMessage">{formErrors.user_name}</p>
            <input
              className="c-text-input"
              type="text"
              placeholder="Adres e-mail"
              name="user_email"
              value={formValues.user_email}
              onChange={handleChange}
            />
            <p className="c-errorMessage">{formErrors.user_email}</p>
            <input
              className="c-text-input"
              type="text"
              placeholder="Temat wiadomości"
              name="subject"
              value={formValues.subject}
              onChange={handleChange}
            />
            <p className="c-errorMessage">{formErrors.subject}</p>
            <textarea
              rows="4"
              placeholder="Wiadomość"
              name="message"
              value={formValues.message}
              onChange={handleChange}
            />
            <p className="c-errorMessage">{formErrors.message}</p>

            <div className="c-checkbox">
              <input
                type="checkbox"
                id="agree"
                className="c-input-checkbox"
                checked={agree}
                onChange={checkboxClicked}
              />
              <label htmlFor="agree" className="c-input-checkbox-label">
                Potwierdzam zapoznanie się z&nbsp;<a target="_blank" href="polityka_prywatnosci.pdf">polityką prywatności</a>
              </label>
              <p className="c-errorMessage">{formErrors.agree}</p>

            </div>
            <div className="c-submitButton-div">
              <button className="c-submitButton">Wyślij</button>
            </div>
            {sendButtonClicked && (
              <div className="c-mailSuccess">
                {done ? (
                  ""
                ) : (
                  <Backdrop
                    sx={{
                      color: "#fff",
                      zIndex: (theme) => theme.zIndex.drawer + 1,
                    }}
                    open
                  >
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
              </div>
            )}
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
